@import "./core/functions/index.import.scss";

// Options
$kendo-enable-rounded: true !default;
$kendo-enable-shadows: true !default;
$kendo-enable-gradients: false !default;
$kendo-enable-transitions: true !default;
$kendo-enable-focus-contrast: false !default;
$kendo-enable-typography: false !default;

$kendo-use-input-button-width: false !default;
$kendo-use-input-spinner-width: false !default;
$kendo-use-input-spinner-icon-offset: false !default;

$kendo-auto-bootstrap: true !default;


// Spacing
$kendo-spacing: (
    0: 0,
    1px: 1px,
    0.5: 2px,
    1: 4px,
    1.5: 6px,
    2: 8px,
    2.5: 10px,
    3: 12px,
    3.5: 14px,
    4: 16px,
    4.5: 18px,
    5: 20px,
    5.5: 22px,
    6: 24px,
    6.5: 26px,
    7: 28px,
    7.5: 30px,
    8: 32px,
    9: 36px,
    10: 40px,
    11: 44px,
    12: 48px,
    13: 52px,
    14: 56px,
    15: 60px,
    16: 64px,
    17: 68px,
    18: 72px,
    19: 76px,
    20: 80px,
    21: 84px,
    22: 88px,
    23: 92px,
    24: 96px
) !default;


// Shadows

/// Shadow for switch.
/// Equivalent to material elevation 1.
/// @group shadows
/// @deprecated Use the `$kendo-elevation` map instead.
$box-shadow-depth-1: k-elevation(1) !default;
/// Shadow for resting state of button, card and other widgets.
/// Equivalent to material elevation 2.
/// @group shadows
/// @deprecated Use the `$kendo-elevation` map instead.
$box-shadow-depth-2: k-elevation(2) !default;
/// Shadow for menu, popups and raised state of button and card.
/// Equivalent to material elevation 3.
/// @group shadows
/// @deprecated Use the `$kendo-elevation` map instead.
$box-shadow-depth-3: k-elevation(3) !default;
/// Shadow for drawers and other overlaying elements.
/// Equivalent to material elevation 4.
/// @group shadows
/// @deprecated Use the `$kendo-elevation` map instead.
$box-shadow-depth-4: k-elevation(4) !default;
/// Shadow for window and dialog.
/// Equivalent to material elevation 6.
/// @group shadows
/// @deprecated Use the `$kendo-elevation` map instead.
$box-shadow-depth-5: k-elevation(5) !default;
/// Shadow for window and dialog.
/// Equivalent to material elevation 8.
/// @group shadows
/// @deprecated Use the `$kendo-elevation` map instead.
$box-shadow-depth-6: k-elevation(6) !default;
/// Shadow for window and dialog.
/// Equivalent to material elevation 12.
/// @group shadows
/// @deprecated Use the `$kendo-elevation` map instead.
$box-shadow-depth-7: k-elevation(7) !default;
/// Shadow for window and dialog.
/// Equivalent to material elevation 16.
/// @group shadows
/// @deprecated Use the `$kendo-elevation` map instead.
$box-shadow-depth-8: k-elevation(8) !default;
/// Shadow for window and dialog.
/// Equivalent to material elevation 24.
/// @group shadows
/// @deprecated Use the `$kendo-elevation` map instead.
$box-shadow-depth-9: k-elevation(9) !default;


// Icons
$kendo-icon-size: 16px !default;

$kendo-icon-size-xs: calc( #{$kendo-icon-size} * .75 ) !default;
$kendo-icon-size-sm: calc( #{$kendo-icon-size} * .875 ) !default;
$kendo-icon-size-md: $kendo-icon-size !default;
$kendo-icon-size-lg: calc( #{$kendo-icon-size} * 1.25 ) !default;
$kendo-icon-size-xl: calc( #{$kendo-icon-size} * 1.5 ) !default;
$kendo-icon-size-xxl: calc( #{$kendo-icon-size} * 2 ) !default;
$kendo-icon-size-xxxl: calc( #{$kendo-icon-size} * 3 ) !default;

$kendo-icon-spacing: k-map-get( $kendo-spacing, 2 ) !default;
$kendo-icon-padding: k-map-get( $kendo-spacing, 1 ) !default;

// Metrics
$kendo-padding-x: 16px !default;
$kendo-padding-y: 4px !default;
$kendo-padding-sm-x: k-map-get( $kendo-spacing, 2 ) !default;
$kendo-padding-sm-y: k-map-get( $kendo-spacing, 0.5 ) !default;
$kendo-padding-md-x: k-map-get( $kendo-spacing, 4 ) !default;
$kendo-padding-md-y: k-map-get( $kendo-spacing, 1 ) !default;
$kendo-padding-lg-x: k-map-get( $kendo-spacing, 6 ) !default;
$kendo-padding-lg-y: k-map-get( $kendo-spacing, 1.5 ) !default;

/// Border radius for all components.
$kendo-border-radius: k-map-get( $kendo-spacing, 1 ) !default;
$kendo-border-radius-sm: k-math-div( $kendo-border-radius, 2 ) !default;
$kendo-border-radius-md: $kendo-border-radius !default;
$kendo-border-radius-lg: $kendo-border-radius * 1.5 !default;

$kendo-border-radii: (
    DEFAULT: $kendo-border-radius-md,
    0: 0,
    sm: $kendo-border-radius-sm,
    md: $kendo-border-radius-md,
    lg: $kendo-border-radius-lg,
    full: 9999px
) !default;


$kendo-zindex-popup: 1 !default;
$kendo-zindex-window: 2 !default;
$kendo-zindex-loading: 100 !default;


// Typography

/// Base font size across all components.
/// @group typography
$kendo-font-size: 14px !default;
$kendo-font-size-xs: 10px !default;
$kendo-font-size-sm: 12px !default;
$kendo-font-size-md: 14px !default;
$kendo-font-size-lg: 16px !default;
$kendo-font-size-xl: 20px !default;

$kendo-font-sizes: (
    xs: $kendo-font-size-xs,
    sm: $kendo-font-size-sm,
    md: $kendo-font-size-md,
    lg: $kendo-font-size-lg,
    xl: $kendo-font-size-xl
) !default;

/// Font family for text.
/// @group typography
$kendo-font-family-sans-serif: Roboto, "Helvetica Neue", sans-serif !default;

/// Font family across all components.
/// @group typography
$kendo-font-family: Roboto, "Helvetica Neue", sans-serif !default;

/// Font family for monospaced text. Used for styling the code.
/// @group typography
$kendo-font-family-monospace: Consolas, "Ubuntu Mono", "Lucida Console", "Courier New", monospace !default;

/// Font family across all components.
/// @group typography
$kendo-font-family: Roboto, "Helvetica Neue", sans-serif !default;

/// Line height used along with $kendo-font-size.
/// @group typography
$kendo-line-height: k-math-div( 28, 14 ) !default;
$kendo-line-height-xs: 1 !default;
$kendo-line-height-sm: 1.2 !default;
$kendo-line-height-md: $kendo-line-height !default;
$kendo-line-height-lg: 1.5 !default;
$kendo-line-height-em: calc( #{$kendo-line-height} * 1em ) !default;

// Font weight
$kendo-font-weight-light: 300 !default;
$kendo-font-weight-normal: 400 !default;
$kendo-font-weight-medium: 500 !default;
$kendo-font-weight-semibold: 600 !default;
$kendo-font-weight-bold: 700 !default;

// Letter Spacing
$kendo-letter-spacing: null !default;

$kendo-transition: color .2s ease-in-out, background-color .2s ease-in-out, border-color .2s ease-in-out, box-shadow .2s ease-in-out !default;

// Disabled mixin variables
$kendo-disabled-filter: grayscale(.1) !default;
$kendo-disabled-opacity: .6 !default;

$kendo-disabled-styling: (
    opacity: $kendo-disabled-opacity,
    filter: $kendo-disabled-filter
) !default;

// Loading
$kendo-loading-opacity: .3 !default;
$kendo-zindex-loading: 100 !default;
