@import "../list/_variables.scss";


// Pager

/// The horizontal padding of the Pager.
/// @group pager
$kendo-pager-padding-x: k-map-get( $kendo-spacing, 2 ) !default;
/// The horizontal padding of the small Pager.
/// @group pager
$kendo-pager-sm-padding-x: k-map-get( $kendo-spacing, 1 ) !default;
/// The horizontal padding of the medium Pager.
/// @group pager
$kendo-pager-md-padding-x: k-map-get( $kendo-spacing, 2 ) !default;
/// The horizontal padding of the large Pager.
/// @group pager
$kendo-pager-lg-padding-x: k-map-get( $kendo-spacing, 2.5 ) !default;
/// The vertical padding of the Pager.
/// @group pager
$kendo-pager-padding-y: $kendo-pager-padding-x !default;
/// The vertical padding of the small Pager.
/// @group pager
$kendo-pager-sm-padding-y: $kendo-pager-sm-padding-x !default;
/// The vertical padding of the medium Pager.
/// @group pager
$kendo-pager-md-padding-y: $kendo-pager-md-padding-x !default;
/// The vertical padding of the large Pager.
/// @group pager
$kendo-pager-lg-padding-y: $kendo-pager-lg-padding-x !default;

/// The minimum width of the items in the small Pager.
/// @group pager
$kendo-pager-sm-item-min-width: $kendo-button-sm-calc-size !default;
/// The minimum width of the items in the medium Pagers.
/// @group pager
$kendo-pager-md-item-min-width: $kendo-button-md-calc-size !default;
/// The minimum width of the items in the large Pagers.
/// @group pager
$kendo-pager-lg-item-min-width: $kendo-button-lg-calc-size !default;

/// The margin between the item groups in the small Pager.
/// @group pager
$kendo-pager-sm-item-group-spacing: k-map-get( $kendo-spacing, 1.5 ) !default;
/// The margin between the item groups in the medium Pager.
/// @group pager
$kendo-pager-md-item-group-spacing: k-map-get( $kendo-spacing, 2 ) !default;
/// The margin between the item groups in the large Pager.
/// @group pager
$kendo-pager-lg-item-group-spacing: k-map-get( $kendo-spacing, 2.5 ) !default;

/// The border width of the Pager.
/// @group pager
$kendo-pager-border-width: 1px !default;
/// The font family of the Pager.
/// @group pager
$kendo-pager-font-family: $kendo-font-family !default;
/// The font size of the Pager.
/// @group pager
$kendo-pager-font-size: $kendo-font-size-md !default;
/// The line height of the Pager.
/// @group pager
$kendo-pager-line-height: $kendo-line-height-md !default;
/// The background color of the Pager.
/// @group pager
$kendo-pager-bg: $kendo-component-bg !default;
/// The text color of the Pager.
/// @group pager
$kendo-pager-text: if($kendo-enable-color-system, k-color( subtle ), if( $kendo-is-dark-theme, $light-secondary-text, $dark-secondary-text )) !default;
/// The border color of the Pager.
/// @group pager
$kendo-pager-border: $kendo-component-border !default;

/// The background color of the focused Pager.
/// @group pager
$kendo-pager-focus-bg: if($kendo-enable-color-system, k-color( surface ), k-try-shade( $kendo-pager-bg, .5 )) !default;
/// The box shadow of the focused Pager.
/// @group pager
$kendo-pager-focus-shadow: null !default;

/// The spacing between the Pager sections.
/// @group pager
$kendo-pager-section-spacing: $kendo-pager-padding-x !default;

/// The border width of the Pager items.
/// @group pager
$kendo-pager-item-border-width: 0px !default;
/// The border radius of the Pager items.
/// @group pager
$kendo-pager-item-border-radius: 5em !default;
/// The spacing around the Pager items.
/// @group pager
$kendo-pager-item-spacing: 0px !default;
/// The background color of the Pager items.
/// @group pager
$kendo-pager-item-bg: null !default;
/// The text color of the Pager items.
/// @group pager
$kendo-pager-item-text: null !default;
/// The border color of the Pager items.
/// @group pager
$kendo-pager-item-border: null !default;

/// The background color of the hovered Pager items.
/// @group pager
$kendo-pager-item-hover-bg: $kendo-list-item-hover-bg !default;
/// The text color of the hovered Pager items.
/// @group pager
$kendo-pager-item-hover-text: $kendo-pager-text !default;
/// The border color of the hovered Pager items.
/// @group pager
$kendo-pager-item-hover-border: null !default;
/// The background color of the selected Pager items.
/// @group pager
$kendo-pager-item-selected-bg: $kendo-color-primary !default;
/// The text color of the selected Pager items.
/// @group pager
$kendo-pager-item-selected-text: $kendo-color-primary-contrast !default;
/// The border color of the selected Pager items.
/// @group pager
$kendo-pager-item-selected-border: null !default;

/// The border radius of the Pager numbers.
/// @group pager
$kendo-pager-number-border-radius: $kendo-pager-item-border-radius !default;

/// The opacity of the focused Pager items.
/// @group pager
$kendo-pager-item-focus-opacity: .12 !default;
/// The background color of the focused Pager items.
/// @group pager
$kendo-pager-item-focus-bg: rgba($kendo-list-item-hover-bg, $kendo-pager-item-focus-opacity) !default;
/// The box shadow of the focused Pager items.
/// @group pager
$kendo-pager-item-focus-shadow: null !default;

/// The width of the Inputs in the Pager.
/// @group pager
$kendo-pager-input-width: 5em !default;

/// The width of the DropDowns in the small Pager.
/// @group pager
$kendo-pager-sm-dropdown-width: 5em !default;
/// The width of the DropDowns in the medium Pager.
/// @group pager
$kendo-pager-md-dropdown-width: 5em !default;
/// The width of the DropDowns in the large Pager.
/// @group pager
$kendo-pager-lg-dropdown-width: 5em !default;

/// The sizes map of the Pager.
/// @group pager
$kendo-pager-sizes: (
    sm: (
        padding-x: $kendo-pager-sm-padding-x,
        padding-y: $kendo-pager-sm-padding-y,
        item-group-spacing: $kendo-pager-sm-item-group-spacing,
        item-min-width: $kendo-pager-sm-item-min-width,
        pager-dropdown-width: $kendo-pager-sm-dropdown-width
    ),
    md: (
        padding-x: $kendo-pager-md-padding-x,
        padding-y: $kendo-pager-md-padding-y,
        item-group-spacing: $kendo-pager-md-item-group-spacing,
        item-min-width: $kendo-pager-md-item-min-width,
        pager-dropdown-width: $kendo-pager-md-dropdown-width
    ),
    lg: (
        padding-x: $kendo-pager-lg-padding-x,
        padding-y: $kendo-pager-lg-padding-y,
        item-group-spacing: $kendo-pager-lg-item-group-spacing,
        item-min-width: $kendo-pager-lg-item-min-width,
        pager-dropdown-width: $kendo-pager-lg-dropdown-width
    )
) !default;
