@import "@progress/kendo-theme-default/scss/toolbar/_theme.scss";


@mixin kendo-toolbar--theme() {

    @include kendo-toolbar--theme-base();

    .k-toolbar {

        .k-button-solid-base {
            background-color: if($kendo-enable-color-system, k-color( base-subtle ), k-try-shade( $kendo-button-bg ));
            border-color: if($kendo-enable-color-system, k-color( base-subtle ), k-try-shade( $kendo-button-bg ));

            &.k-input-button,
            &.k-input-spinner .k-spinner-increase,
            &.k-input-spinner .k-spinner-decrease {
                background: none;
                border-color: transparent;
            }
        }

    }

}
