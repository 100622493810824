@include exports( "common/layout" ) {

    // Layout
    .k-reset {
        margin: 0;
        padding: 0;
        border-width: 0;
        outline: 0;
        text-decoration: none;
        font: inherit;
        list-style: none;
    }

    kendo-sortable {
        display: block;
    }


    // Links
    .k-link,
    .k-link:hover {
        color: inherit;
        text-decoration: none;
        outline: 0;
        cursor: pointer;
    }

    // Outline
    .k-content {
        outline: 0;
    }


    // Centering
    .k-centered {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }


    // Disable mouse events
    .k-no-click {
        pointer-events: none;
    }


    // Off-screen container used during PDF export
    .k-pdf-export-shadow {
        position: absolute;
        overflow: hidden;
        left: -15000px;
        width: 14400px;
    }

    // PDF export icons fix
    .kendo-pdf-hide-pseudo-elements::before,
    .kendo-pdf-hide-pseudo-elements::after {
        display: none !important; // stylelint-disable-line declaration-no-important
    }
}


@include exports( "common/theme" ) {

    // Text selection
    .k-text-selection {
        ::selection {
            background-color: $kendo-color-primary;
            color: $kendo-color-primary-contrast;
        }
    }

    // Panels
    .k-block,
    .k-panel {
        @include border-radius( $kendo-border-radius-md );
        @include fill(
            $kendo-component-text,
            $kendo-component-bg,
            $kendo-component-border
        );
        padding: 0;
        border-width: 1px;
        border-style: solid;
        box-sizing: border-box;

        > .k-header {
            @include border-top-radius( $kendo-border-radius-md );
            @include fill(
                $kendo-component-header-text,
                $kendo-component-header-bg,
                $kendo-component-header-border,
                $kendo-component-header-gradient
            );
            padding-inline: k-map-get( $kendo-spacing, 2 );
            padding-block: k-map-get( $kendo-spacing, 1 );
        }
        > .k-content {
            padding-inline: k-map-get( $kendo-spacing, 2 );
            padding-block: k-map-get( $kendo-spacing, 1 );
        }
    }
    .k-content {
        @include fill(
            $kendo-component-text,
            $kendo-component-bg,
            $kendo-component-border
        );
    }

}


@include exports( "common/disabled" ) {

    // Disabled state
    .k-disabled,
    .k-widget[disabled] {
        @include disabled( $kendo-disabled-styling );


        .k-link {
            cursor: default;
            outline: 0;
        }


        // Nested disabled
        [disabled],
        .k-disabled {
            opacity: 1;
            filter: grayscale(0);
        }
    }

}


@include exports( "common/misc/hr" ) {

    .k-hr {
        margin: k-map-get( $kendo-spacing, 4 ) auto;
        padding: 0;
        height: 0;
        border-width: 1px 0 0;
        border-style: solid;
        border-color: $kendo-component-border;
        display: block;
        float: none;
        clear: both;
    }

    // Horizontal rule
    .k-d-flex-row > .k-hr {
        margin: 0;
        width: 0;
        height: auto;
        border-width: 0 0 0 1px;
        flex: 0 0 auto;
    }

    // Vertical rule
    .k-d-flex-col > .k-hr {
        margin: 0;
        flex: 0 0 auto;
    }

}


@include exports( "common/misc/dirty" ) {

    // Dirty indicator
    .k-dirty {
        margin: 0;
        padding: 0;
        width: 0;
        height: 0;
        border-width: 3px;
        border-style: solid;
        border-color: $kendo-color-error $kendo-color-error transparent transparent;
        position: absolute;
        top: 0;
        right: 0;
    }

    [dir="rtl"],
    .k-rtl {
        .k-dirty {
            border-color: transparent transparent $kendo-color-error $kendo-color-error;
            right: auto;
            left: 0;
        }
    }

}
