@import "@progress/kendo-theme-default/scss/floating-label/_theme.scss";


@mixin kendo-floating-label--theme() {

    @include kendo-floating-label--theme-base();

    // Floating label
    .k-floating-label-container {

        &.k-invalid + .k-label,
        .k-invalid + .k-label,
        .ng-invalid.ng-touched + .k-label,
        .ng-invalid.ng-dirty + .k-label {
            @include fill( $color: $kendo-color-error );
        }
    }

}
