@import "../list/_variables.scss";


// CheckBox

/// The border width of the CheckBox.
/// @group checkbox
$kendo-checkbox-border-width: 2px !default;

/// The size of a small CheckBox.
/// @group checkbox
$kendo-checkbox-sm-size: k-map-get( $kendo-spacing, 3 ) !default;
/// The size of a medium CheckBox.
/// @group checkbox
$kendo-checkbox-md-size: k-map-get( $kendo-spacing, 4 ) !default;
/// The size of a large CheckBox.
/// @group checkbox
$kendo-checkbox-lg-size: k-map-get( $kendo-spacing, 5 ) !default;

/// The glyph size of a small CheckBox.
/// @group checkbox
$kendo-checkbox-sm-glyph-size: k-map-get( $kendo-spacing, 2.5 ) !default;
/// The glyph size of a medium CheckBox.
/// @group checkbox
$kendo-checkbox-md-glyph-size: k-map-get( $kendo-spacing, 3.5 ) !default;
/// The glyph size of a large CheckBox.
/// @group checkbox
$kendo-checkbox-lg-glyph-size: k-map-get( $kendo-spacing, 4.5 ) !default;

/// The ripple size of a small CheckBox.
/// @group checkbox
$kendo-checkbox-sm-ripple-size: 300% !default;
/// The ripple size of a medium CheckBox.
/// @group checkbox
$kendo-checkbox-md-ripple-size: 300% !default;
/// The ripple size of a large CheckBox.
/// @group checkbox
$kendo-checkbox-lg-ripple-size: 300% !default;

// A map with the different CheckBox sizes.
$kendo-checkbox-sizes: (
    sm: (
        size: $kendo-checkbox-sm-size,
        glyph-size: $kendo-checkbox-sm-glyph-size,
        ripple-size: $kendo-checkbox-sm-ripple-size
    ),
    md: (
        size: $kendo-checkbox-md-size,
        glyph-size: $kendo-checkbox-md-glyph-size,
        ripple-size: $kendo-checkbox-md-ripple-size
    ),
    lg: (
        size: $kendo-checkbox-lg-size,
        glyph-size: $kendo-checkbox-lg-glyph-size,
        ripple-size: $kendo-checkbox-lg-ripple-size
    )
) !default;

/// The background color of the CheckBox.
/// @group checkbox
$kendo-checkbox-bg: null !default;
/// The text color of the CheckBox.
/// @group checkbox
$kendo-checkbox-text: null !default;
/// The border color of the CheckBox.
/// @group checkbox
$kendo-checkbox-border: if($kendo-enable-color-system, color-mix(in srgb, k-color( on-app-surface ) 54%, transparent), rgba( if( $kendo-is-dark-theme, $kendo-color-white, $kendo-color-black ), .54 )) !default;

/// The background color of the hovered CheckBox.
/// @group checkbox
$kendo-checkbox-hover-bg: null !default;
/// The text color of the hovered CheckBox.
/// @group checkbox
$kendo-checkbox-hover-text: null !default;
/// The border color of the hovered CheckBox.
/// @group checkbox
$kendo-checkbox-hover-border: null !default;

/// The background color of the checked CheckBox.
/// @group checkbox
$kendo-checkbox-checked-bg: $kendo-color-primary !default;
/// The text color of the checked CheckBox.
/// @group checkbox
$kendo-checkbox-checked-text: if($kendo-enable-color-system, k-color( on-primary ), k-contrast-color( $kendo-checkbox-checked-bg )) !default;
/// The border color of the checked CheckBox.
/// @group checkbox
$kendo-checkbox-checked-border: $kendo-checkbox-checked-bg !default;

/// The background color of the indeterminate CheckBox.
/// @group checkbox
$kendo-checkbox-indeterminate-bg: $kendo-checkbox-checked-bg !default;
/// The text color of the indeterminate CheckBox.
/// @group checkbox
$kendo-checkbox-indeterminate-text: $kendo-checkbox-checked-text !default;
/// The border color of the indeterminate CheckBox.
/// @group checkbox
$kendo-checkbox-indeterminate-border: $kendo-checkbox-checked-border !default;

/// The border color of the focused CheckBox.
/// @group checkbox
$kendo-checkbox-focus-border: null !default;
/// The box shadow of the focused CheckBox.
/// @group checkbox
$kendo-checkbox-focus-shadow: null !default;
/// The border color of the focused and checked CheckBox.
/// @group checkbox
$kendo-checkbox-focus-checked-border: null !default;
/// The box shadow of the focused and checked CheckBox.
/// @group checkbox
$kendo-checkbox-focus-checked-shadow: null !default;

/// The background color of the disabled CheckBox.
/// @group checkbox
$kendo-checkbox-disabled-bg: null !default;
/// The text color of the disabled CheckBox.
/// @group checkbox
$kendo-checkbox-disabled-text: null !default;
/// The border color of the disabled CheckBox.
/// @group checkbox
$kendo-checkbox-disabled-border: if($kendo-enable-color-system, k-color( base-emphasis ), k-try-shade( $kendo-component-bg, 4 )) !default;


/// The background color of the disabled and checked CheckBox.
/// @group checkbox
$kendo-checkbox-disabled-checked-bg: $kendo-checkbox-disabled-border !default;
/// The text color of the disabled and checked CheckBox.
/// @group checkbox
$kendo-checkbox-disabled-checked-text: if($kendo-enable-color-system, k-color( on-base ), k-contrast-color( $kendo-checkbox-disabled-checked-bg )) !default;
/// The border color of the disabled and checked CheckBox.
/// @group checkbox
$kendo-checkbox-disabled-checked-border: $kendo-checkbox-disabled-checked-bg !default;

/// The background color of an invalid CheckBox.
/// @group checkbox
$kendo-checkbox-invalid-bg: null !default;
/// The text color of an invalid CheckBox.
/// @group checkbox
$kendo-checkbox-invalid-text: $kendo-invalid-text !default;
/// The border color of an invalid CheckBox.
/// @group checkbox
$kendo-checkbox-invalid-border: $kendo-invalid-border !default;


// CheckBox indicator

/// The type of the CheckBox indicator.
/// @group checkbox
$kendo-checkbox-indicator-type: image !default;

/// The font family of the CheckBox indicator glyph.
/// @group checkbox
$kendo-checkbox-glyph-font-family: "WebComponentsIcons", monospace !default;
$kendo-checkbox-glyph-size: 10px !default;
/// The glyph of the CheckBox indicator.
/// @group checkbox
$kendo-checkbox-checked-glyph: "\e118" !default;
/// The glyph of the indeterminate CheckBox indicator.
/// @group checkbox
$kendo-checkbox-indeterminate-glyph: "\e121" !default;

/// The image for a checked CheckBox indicator.
/// @group checkbox
$kendo-checkbox-checked-image: k-escape-svg( url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path fill='none' stroke='#{$kendo-checkbox-checked-text}' stroke-width='3' d='M4.1,12.7 9,17.6 20.3,6.3'/></svg>") ) !default;
/// The image for a indeterminate CheckBox indicator.
/// @group checkbox
$kendo-checkbox-indeterminate-image: k-escape-svg( url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$kendo-checkbox-indeterminate-text}' stroke-width='3' d='M4 10h12'/></svg>") ) !default;


// CheckBox label

/// The horizontal margin of the CheckBox inside a label.
/// @group checkbox
$kendo-checkbox-label-margin-x: k-map-get( $kendo-spacing, 1 ) !default;


// CheckBox list

/// The spacing between the items in a horizontal CheckBox list.
/// @group checkbox
$kendo-checkbox-list-spacing: k-map-get( $kendo-spacing, 4 ) !default;
/// The horizontal padding of the CheckBox list items.
/// @group checkbox
$kendo-checkbox-list-item-padding-x: 0px !default;
/// The vertical padding of the CheckBox list items.
/// @group checkbox
$kendo-checkbox-list-item-padding-y: $kendo-list-md-item-padding-y !default;


// CheckBox ripple

/// The background color of the CheckBox' ripple.
/// @group checkbox
$kendo-checkbox-ripple-bg: $kendo-color-primary !default;
/// The opacity of the CheckBox' ripple.
/// @group checkbox
$kendo-checkbox-ripple-opacity: .2 !default;
