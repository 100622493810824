// Tabstrip
$kendo-tabstrip-wrapper-padding-x: 0px !default;
$kendo-tabstrip-wrapper-padding-y: 0px !default;
$kendo-tabstrip-wrapper-border-width: 0px !default;

$kendo-tabstrip-font-family: $kendo-font-family !default;
$kendo-tabstrip-font-size: $kendo-font-size-md !default;
$kendo-tabstrip-line-height: k-math-div( 20, 14 ) !default;
$kendo-tabstrip-border-width: 1px !default;

$kendo-tabstrip-wrapper-bg: null !default;
$kendo-tabstrip-wrapper-text: null !default;
$kendo-tabstrip-wrapper-border: null !default;

/// Background color of tabstrip component
/// @group tabstrip
$kendo-tabstrip-bg: null !default;
/// Text color of tabstrip component
/// @group tabstrip
$kendo-tabstrip-text: $kendo-subtle-text !default;
/// Border color of tabstrip component
/// @group tabstrip
$kendo-tabstrip-border: $kendo-component-border !default;

/// Horizontal padding of tabs
/// @group tabstrip
$kendo-tabstrip-item-padding-x: 24px !default;
/// Vertical padding of tabs
/// @group tabstrip
$kendo-tabstrip-item-padding-y: 14px !default;
/// Width of border around of tabs
/// @group tabstrip
$kendo-tabstrip-item-border-width: 0px !default;
/// Border radius of tabs
/// @group tabstrip
$kendo-tabstrip-item-border-radius: 0px !default;
/// Spacing between tabs
/// @group tabstrip
$kendo-tabstrip-item-gap: 0px !default;

/// Background color of tabs
/// @group tabstrip
$kendo-tabstrip-item-bg: null !default;
/// Text color of tabs
/// @group tabstrip
$kendo-tabstrip-item-text: $kendo-subtle-text !default;
/// Border color of tabs
/// @group tabstrip
$kendo-tabstrip-item-border: null !default;
/// Background gradient of tabs
/// @group tabstrip
$kendo-tabstrip-item-gradient: null !default;

/// Background color of hovered tabs
/// @group tabstrip
$kendo-tabstrip-item-hover-bg: $kendo-hover-bg !default;
/// Text color of hovered tabs
/// @group tabstrip
$kendo-tabstrip-item-hover-text: $kendo-body-text !default;
/// Border color of hovered tabs
/// @group tabstrip
$kendo-tabstrip-item-hover-border: null !default;
/// Background gradient of hovered tabs
/// @group tabstrip
$kendo-tabstrip-item-hover-gradient: null !default;

/// Background color of selected tabs
/// @group tabstrip
$kendo-tabstrip-item-selected-bg: null !default;
/// Text color of selected tabs
/// @group tabstrip
$kendo-tabstrip-item-selected-text: $kendo-body-text !default;
/// Border color of selected tabs
/// @group tabstrip
$kendo-tabstrip-item-selected-border: null !default;
/// Background gradient of selected tabs
/// @group tabstrip
$kendo-tabstrip-item-selected-gradient: null !default;

$kendo-tabstrip-item-focus-shadow: $kendo-list-item-focus-shadow !default;

$kendo-tabstrip-item-dragging-shadow: $box-shadow-depth-3 !default;

$kendo-tabstrip-item-disabled-bg: null !default;
$kendo-tabstrip-item-disabled-text: if($kendo-enable-color-system, rgba( k-color( on-app-surface, rgba ), .32 ), k-map-get( $theme, disabled-text )) !default;
$kendo-tabstrip-item-disabled-border: null !default;
$kendo-tabstrip-item-disabled-gradient: null !default;
$kendo-tabstrip-item-disabled-opacity: 1 !default;
$kendo-tabstrip-item-disabled-filter: none !default;

$kendo-tabstrip-indicator-size: 2px !default;
$kendo-tabstrip-indicator-color: $kendo-color-primary !default;

/// Horizontal padding of tabstrip content
/// @group tabstrip
$kendo-tabstrip-content-padding-x: k-map-get( $kendo-spacing, 4 ) !default;
/// Vertical padding of tabstrip content
/// @group tabstrip
$kendo-tabstrip-content-padding-y: k-map-get( $kendo-spacing, 4 ) !default;
/// Width of border around tabstrip content
/// @group tabstrip
$kendo-tabstrip-content-border-width: 0px !default;

/// Background color of tabstrip content
/// @group tabstrip
$kendo-tabstrip-content-bg: transparent !default;
/// Text color of tabstrip content
/// @group tabstrip
$kendo-tabstrip-content-text: null !default;
/// Border color of tabstrip content
/// @group tabstrip
$kendo-tabstrip-content-border: null !default;
/// Border color of tabstrip focused content
/// @group tabstrip
$kendo-tabstrip-content-focus-border: $kendo-component-text !default;
