@import "@progress/kendo-theme-default/scss/button/_theme.scss";


@mixin kendo-button--theme() {

    @include kendo-button--theme-base();

    // Solid button
    @each $name, $color in $kendo-button-theme-colors {
        .k-button-solid-#{$name} {
            @include box-shadow( $kendo-button-shadow );
            color: if( $name == "base", $kendo-button-text, if($kendo-enable-color-system, k-color( on-#{$name} ), k-contrast-color( $color )) );
            background-color: if( $name == "base", $kendo-button-bg, $color );
            border-color: if( $name == "base", $kendo-button-bg, $color );

            // Hover state
            &:hover,
            &.k-hover {
                @include box-shadow( $kendo-button-hover-shadow );
            }

            // Focus state
            &:focus,
            &.k-focus {
                @include box-shadow( $kendo-button-focus-shadow );
            }

            // Active state
            &:active,
            &.k-active {
                @include box-shadow( $kendo-button-active-shadow );
            }

            // Selected state
            &.k-selected {
                @include box-shadow( $kendo-button-selected-shadow );
            }

            // Disabled state
            &:disabled,
            &.k-disabled {
                @include box-shadow( $kendo-button-disabled-shadow );
            }
        }
    }


    // Outline button
    @each $name, $color in $kendo-button-theme-colors {
        .k-button-outline-#{$name} {
            color: if( $name == "base", $kendo-button-text, if($kendo-enable-color-system, k-color( #{$name}-on-surface ), $color) );

            &:hover,
            &.k-hover,
            &:focus,
            &.k-focus,
            &:active,
            &.k-active,
            &.k-selected {
                color: if( $name == "base", $kendo-button-text, if($kendo-enable-color-system, k-color( #{$name}-on-surface ), $color) );
            }

            // Disabled state
            &:disabled,
            &.k-disabled {
                color: $kendo-button-disabled-text;
            }
        }
    }


    // Link button
    @each $name, $color in $kendo-button-theme-colors {
        .k-button-link-#{$name} {

            // Disabled state
            &:disabled,
            &.k-disabled {
                color: $kendo-button-disabled-text;
            }
        }
    }


    // Menu button
    .k-menu-button {}


    // Button group
    .k-button-group {
        @include box-shadow( $kendo-button-shadow );

        .k-button {
            @include box-shadow( none );
        }

        // Disabled state
        &:disabled,
        &[disabled],
        &.k-disabled {
            @include box-shadow( none );
        }
    }


    // Variant button group
    .k-button-group-flat,
    .k-button-group-link,
    .k-button-group-outline {
        @include box-shadow( none );
    }

}
