// Input

/// The default width of the Input components.
/// @group input
$kendo-input-default-width: 100% !default;

/// The border width of the Input components.
/// @group input
$kendo-input-border-width: 1px !default;
$kendo-input-border-height: 1px !default;
/// The border radius of the Input components.
/// @group input
$kendo-input-border-radius: null !default;

/// The horizontal padding of the Input components.
/// @group input
$kendo-input-padding-x: k-map-get( $kendo-spacing, 4 ) !default;
/// The horizontal padding of the small Input components.
/// @group input
$kendo-input-sm-padding-x: k-map-get( $kendo-spacing, 4 ) !default;
/// The horizontal padding of the medium Input components.
/// @group input
$kendo-input-md-padding-x: k-map-get( $kendo-spacing, 4 ) !default;
/// The horizontal padding of the large Input components.
/// @group input
$kendo-input-lg-padding-x: k-map-get( $kendo-spacing, 4 ) !default;

/// The vertical padding of the Input components.
/// @group input
$kendo-input-padding-y: k-map-get( $kendo-spacing, 2 ) !default;
/// The vertical padding of the small Input components.
/// @group input
$kendo-input-sm-padding-y: k-map-get( $kendo-spacing, 1.5 ) !default;
/// The vertical padding of the medium Input components.
/// @group input
$kendo-input-md-padding-y: k-map-get( $kendo-spacing, 2 ) !default;
/// The vertical padding of the large Input components.
/// @group input
$kendo-input-lg-padding-y: k-map-get( $kendo-spacing, 2 ) !default;

/// The font family of the Input components.
/// @group input
$kendo-input-font-family: $kendo-font-family !default;

/// The font size of the Input components.
/// @group input
$kendo-input-font-size: $kendo-font-size-lg !default;
/// The font size of the small Input components.
/// @group input
$kendo-input-sm-font-size: $kendo-font-size-lg !default;
/// The font size of the medium Input components.
/// @group input
$kendo-input-md-font-size: $kendo-font-size-lg !default;
/// The font size of the large Input components.
/// @group input
$kendo-input-lg-font-size: $kendo-font-size-lg !default;

/// The line height of the Input components.
/// @group input
$kendo-input-line-height: 1.25 !default;
/// The line height of the small Input components.
/// @group input
$kendo-input-sm-line-height: 1.25 !default;
/// The line height of the medium Input components.
/// @group input
$kendo-input-md-line-height: 1.25 !default;
/// The line height of the large Input components.
/// @group input
$kendo-input-lg-line-height: 1.5 !default;

/// The sizes map for the Input components.
/// @group input
$kendo-input-sizes: (
    sm: (
        padding-x: $kendo-input-sm-padding-x,
        padding-y: $kendo-input-sm-padding-y,
        font-size: $kendo-input-sm-font-size,
        line-height: $kendo-input-sm-line-height,
        button-padding-x: k-map-get( $kendo-spacing, 1 ),
        button-padding-y: k-map-get( $kendo-spacing, 1 )
    ),
    md: (
        padding-x: $kendo-input-md-padding-x,
        padding-y: $kendo-input-md-padding-y,
        font-size: $kendo-input-md-font-size,
        line-height: $kendo-input-md-line-height,
        button-padding-x: k-map-get( $kendo-spacing, 1 ),
        button-padding-y: k-map-get( $kendo-spacing, 1 )
    ),
    lg: (
        padding-x: $kendo-input-lg-padding-x,
        padding-y: $kendo-input-lg-padding-y,
        font-size: $kendo-input-lg-font-size,
        line-height: $kendo-input-lg-line-height,
        button-padding-x: k-map-get( $kendo-spacing, 1 ),
        button-padding-y: k-map-get( $kendo-spacing, 1 )
    )
) !default;

/// The background color of the Input components.
/// @group input
$kendo-input-bg: if($kendo-enable-color-system, k-color( surface ), k-try-shade( $kendo-component-bg, .5 )) !default;
/// The text color of the Input components.
/// @group input
$kendo-input-text: $kendo-component-text !default;
/// The border color of the Input components.
/// @group input
$kendo-input-border: if($kendo-enable-color-system, color-mix(in srgb, k-color( on-app-surface ) 44%, transparent), rgba( $kendo-component-border, .38 )) !default;
/// The shadow of the Input components.
/// @group input
$kendo-input-shadow: null !default;

/// The background color of the hovered Input components.
/// @group input
$kendo-input-hover-bg: null !default;
/// The text color of the hovered Input components.
/// @group input
$kendo-input-hover-text: null !default;
/// The border color of the hovered Input components.
/// @group input
$kendo-input-hover-border: $kendo-input-text !default;
/// The shadow of the hovered Input components.
/// @group input
$kendo-input-hover-shadow: null !default;

/// The background color of the focused Input components.
/// @group input
$kendo-input-focus-bg: null !default;
/// The text color of the focused Input components.
/// @group input
$kendo-input-focus-text: null !default;
/// The border color of the focused Input components.
/// @group input
$kendo-input-focus-border: $kendo-color-primary !default;
/// The shadow of the focused Input components.
/// @group input
$kendo-input-focus-shadow: null !default;

/// The background color of the selected Input components.
/// @group input
$kendo-input-selected-bg: null !default;
/// The text color of the selected Input components.
/// @group input
$kendo-input-selected-text: null !default;
/// The background color of the disabled Input components.
/// @group input
$kendo-input-disabled-bg: if($kendo-enable-color-system, color-mix(in srgb, k-color( on-app-surface ) 3%, transparent), k-try-shade( $kendo-component-bg, .25 )) !default;
/// The text color of the disabled Input components.
/// @group input
$kendo-input-disabled-text: if($kendo-enable-color-system, color-mix(in srgb, k-color( on-app-surface ) 27%, transparent), $kendo-disabled-text) !default;
/// The border color of the disabled Input components.
/// @group input
$kendo-input-disabled-border: if($kendo-enable-color-system, color-mix(in srgb, k-color( on-app-surface ) 3%, transparent), rgba( $kendo-component-border, k-math-div( k-color-alpha( $kendo-component-border ), 2 ) )) !default;
/// The gradient of the disabled Input components.
/// @group input
$kendo-input-disabled-gradient: null !default;
/// The shadow of the disabled Input components.
/// @group input
$kendo-input-disabled-shadow: null !default;

/// The background color of the outline Input components.
/// @group input
$kendo-input-outline-bg: null !default;
/// The text color of the outline Input components.
/// @group input
$kendo-input-outline-text: $kendo-input-text !default;
/// The border color of the outline Input components.
/// @group input
$kendo-input-outline-border: $kendo-input-border !default;

/// The background color of the outline hovered Input components.
/// @group input
$kendo-input-outline-hover-bg: null !default;
/// The text color of the outline hovered Input components.
/// @group input
$kendo-input-outline-hover-text: null !default;
/// The border color of the outline hovered Input components.
/// @group input
$kendo-input-outline-hover-border: $kendo-input-hover-border !default;

/// The background color of the outline focused Input components.
/// @group input
$kendo-input-outline-focus-bg: null !default;
/// The text color of the outline focused Input components.
/// @group input
$kendo-input-outline-focus-text: null !default;
/// The border color of the outline focused Input components.
/// @group input
$kendo-input-outline-focus-border: $kendo-input-focus-border !default;
/// The shadow of the outline focused Input components.
/// @group input
$kendo-input-outline-focus-shadow: $kendo-input-focus-shadow !default;

/// The background color of the flat Input components.
/// @group input
$kendo-input-flat-bg: null !default;
/// The text color of the flat Input components.
/// @group input
$kendo-input-flat-text: $kendo-input-text !default;
/// The border color of the flat Input components.
/// @group input
$kendo-input-flat-border: $kendo-input-border !default;

/// The background color of the flat hovered Input components.
/// @group input
$kendo-input-flat-hover-bg: null !default;
/// The text color of the flat hovered Input components.
/// @group input
$kendo-input-flat-hover-text: null !default;
/// The border color of the flat hovered Input components.
/// @group input
$kendo-input-flat-hover-border: $kendo-input-hover-border !default;

/// The background color of the flat focused Input components.
/// @group input
$kendo-input-flat-focus-bg: null !default;
/// The text color of the flat focused Input components.
/// @group input
$kendo-input-flat-focus-text: null !default;
/// The border color of the flat focused Input components.
/// @group input
$kendo-input-flat-focus-border: $kendo-input-focus-border !default;
/// The shadow of the flat focused Input components.
/// @group input
$kendo-input-flat-focus-shadow: $kendo-input-focus-shadow !default;

/// The text color of the Input placeholder.
/// @group input
$kendo-input-placeholder-text: $kendo-subtle-text !default;
/// The opacity of the Input placeholder.
/// @group input
$kendo-input-placeholder-opacity: 1 !default;


/// The color of the Input clear value icon.
/// @group input
$kendo-input-clear-value-text: null !default;
/// The opacity of the Input clear value icon.
/// @group input
$kendo-input-clear-value-opacity: .5 !default;
/// The color of the hovered Input clear value icon.
/// @group input
$kendo-input-clear-value-hover-text: null !default;
/// The opacity of the hovered Input clear value icon.
/// @group input
$kendo-input-clear-value-hover-opacity: 1 !default;

/// The vertical margin of the clear value icon.
/// @group input
$kendo-input-values-margin-y: k-map-get( $kendo-spacing, 0.5 ) !default;
/// The horizontal margin of the clear value icon.
/// @group input
$kendo-input-values-margin-x: $kendo-input-values-margin-y !default;

/// The width of the Input button.
/// @group input
$kendo-input-button-width: null !default;
/// The border width of the Input button.
/// @group input
$kendo-input-button-border-width: 1px !default;
/// The width of the Input spinner button.
/// @group input
$kendo-input-spinner-width: null !default;
/// The icon offset of the Input spinner button.
/// @group input
$kendo-input-spinner-icon-offset: null !default;

/// The color of the Input separator.
/// @group input
$kendo-input-separator-text: rgba( $kendo-component-border, .12 ) !default;
/// The opacity of the Input separator.
/// @group input
$kendo-input-separator-opacity: .5 !default;

/// The text color of the Input prefix.
/// @group input
$kendo-input-prefix-text: $kendo-subtle-text !default;
/// The text color of the Input suffix.
/// @group input
$kendo-input-suffix-text: $kendo-subtle-text !default;

/// The border color of the invalid Input components.
/// @group input
$kendo-input-invalid-border: $kendo-invalid-border !default;
/// The shadow of the invalid Input components.
/// @group input
$kendo-input-invalid-shadow: $kendo-invalid-shadow !default;

/// The background color of the Picker components.
/// @group picker
$kendo-picker-bg: $kendo-input-bg !default;
/// The text color of the Picker components.
/// @group picker
$kendo-picker-text: $kendo-input-text !default;
/// The border color of the Picker components.
/// @group picker
$kendo-picker-border: $kendo-input-border !default;
/// The gradient of the Picker components.
/// @group picker
$kendo-picker-gradient: null !default;
/// The shadow of the Picker components.
/// @group picker
$kendo-picker-shadow: $kendo-input-shadow !default;

/// The background color of the hovered Picker components.
/// @group picker
$kendo-picker-hover-bg: $kendo-input-hover-bg !default;
/// The text color of the hovered Picker components.
/// @group picker
$kendo-picker-hover-text: $kendo-input-hover-text !default;
/// The border color of the hovered Picker components.
/// @group picker
$kendo-picker-hover-border: $kendo-input-hover-border !default;
/// The gradient of the hovered Picker components.
/// @group picker
$kendo-picker-hover-gradient: null !default;
/// The shadow of the hovered Picker components.
/// @group picker
$kendo-picker-hover-shadow: $kendo-input-hover-shadow !default;

/// The background color of the focused Picker components.
/// @group picker
$kendo-picker-focus-bg: $kendo-input-focus-bg !default;
/// The text color of the focused Picker components.
/// @group picker
$kendo-picker-focus-text: $kendo-input-focus-text !default;
/// The border color of the focused Picker components.
/// @group picker
$kendo-picker-focus-border: $kendo-input-focus-border !default;
/// The gradient of the focused Picker components.
/// @group picker
$kendo-picker-focus-gradient: null !default;
/// The shadow of the focused Picker components.
/// @group picker
$kendo-picker-focus-shadow: $kendo-input-focus-shadow !default;

/// The background color of the disabled Picker components.
/// @group picker
$kendo-picker-disabled-bg: $kendo-input-disabled-bg !default;
/// The text color of the disabled Picker components.
/// @group picker
$kendo-picker-disabled-text: $kendo-input-disabled-text !default;
/// The border color of the disabled Picker components.
/// @group picker
$kendo-picker-disabled-border: $kendo-input-disabled-border !default;
/// The gradient of the disabled Picker components.
/// @group picker
$kendo-picker-disabled-gradient: null !default;
/// The shadow of the disabled Picker components.
/// @group picker
$kendo-picker-disabled-shadow: null !default;

/// The background color of the outline Picker components.
/// @group picker
$kendo-picker-outline-bg: null !default;
/// The text color of the outline Picker components.
/// @group picker
$kendo-picker-outline-text: $kendo-picker-text !default;
/// The border color of the outline Picker components.
/// @group picker
$kendo-picker-outline-border: $kendo-picker-border !default;

/// The background color of the outline hovered Picker components.
/// @group picker
$kendo-picker-outline-hover-bg: null !default;
/// The text color of the outline hovered Picker components.
/// @group picker
$kendo-picker-outline-hover-text: null !default;
/// The border color of the outline hovered Picker components.
/// @group picker
$kendo-picker-outline-hover-border: $kendo-picker-hover-border !default;

/// The background color of the outline focused Picker components.
/// @group picker
$kendo-picker-outline-focus-bg: nul !default;
/// The text color of the outline focused Picker components.
/// @group picker
$kendo-picker-outline-focus-text: null !default;
/// The border color of the outline focused Picker components.
/// @group picker
$kendo-picker-outline-focus-border: $kendo-picker-focus-border !default;
/// The shadow of the outline focused Picker components.
/// @group picker
$kendo-picker-outline-focus-shadow: null !default;

/// The background color of the outline hovered and focused Picker components.
/// @group picker
$kendo-picker-outline-hover-focus-bg: null !default;
/// The text color of the outline hovered and focused Picker components.
/// @group picker
$kendo-picker-outline-hover-focus-text: null !default;
/// The border color of the outline hovered and focused Picker components.
/// @group picker
$kendo-picker-outline-hover-focus-border: null !default;

/// The background color of the flat Picker components.
/// @group picker
$kendo-picker-flat-bg: null !default;
/// The text color of the flat Picker components.
/// @group picker
$kendo-picker-flat-text: $kendo-picker-text !default;
/// The border color of the flat Picker components.
/// @group picker
$kendo-picker-flat-border: $kendo-picker-border !default;

/// The background color of the flat hovered Picker components.
/// @group picker
$kendo-picker-flat-hover-bg: null !default;
/// The text color of the flat hovered Picker components.
/// @group picker
$kendo-picker-flat-hover-text: null !default;
/// The border color of the flat hovered Picker components.
/// @group pickers
$kendo-picker-flat-hover-border: $kendo-picker-hover-border !default;

/// The background color of the flat focused Picker components.
/// @group picker
$kendo-picker-flat-focus-bg: null !default;
/// The text color of the flat focused Picker components.
/// @group picker
$kendo-picker-flat-focus-text: null !default;
/// The border color of the flat focused Picker components.
/// @group picker
$kendo-picker-flat-focus-border: $kendo-picker-focus-border !default;
/// The shadow of the flat focused Picker components.
/// @group picker
$kendo-picker-flat-focus-shadow: null !default;

/// The background color of the flat hovered and focused Picker components.
/// @group picker
$kendo-picker-flat-hover-focus-bg: null !default;
/// The text color of the flat hovered and focused Picker components.
/// @group picker
$kendo-picker-flat-hover-focus-text: null !default;
/// The border color of the flat hovered and focused Picker components.
/// @group picker
$kendo-picker-flat-hover-focus-border: null !default;
